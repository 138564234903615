<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <b-img
            width="150"
            :src="require('@/assets/images/logo/logo.png')"
          />
        </b-link>

        <b-card-title class="mb-1">
          Recuperação de senha
        </b-card-title>
        <b-form
          class="auth-login-form mt-2"
          @submit.prevent
        >
          <b-form-group
            label="Senha"
            label-for="senhaInput"
          >
            <validation-provider
              #default="{ errors }"
              name="Senha"
              rules="required"
            >
              <senha-input
                id="senhaInput"
                v-model="form.senha"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Confirmar senha"
            label-for="confirmarSenhaInput"
          >
            <validation-provider
              #default="{ errors }"
              name="Confirmar Senha"
              rules="required"
            >
              <senha-input
                id="confirmarSenhaInput"
                v-model="form.confirmarSenha"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- submit buttons -->
          <b-button
            type="submit"
            variant="primary"
            block
            @click="autenticar"
          >
            Alterar Senha
          </b-button>
        </b-form>
      </b-card>
    </div>
  </div>
</template>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>

<script>
/* eslint-disable import/no-cycle */
import { ValidationProvider } from 'vee-validate'
import { required, email } from '@validations'
import Vue from 'vue'

import api from './shared/services/api'

export default {
  components: {
    ValidationProvider,
    SenhaInput: () => import('@pilar/components/senha-input/SenhaInput.vue'),
  },
  data() {
    return {
      email,
      required,
      form: {
        login: this.$route.query.email,
        senha: '',
        confirmarSenha: '',
        token: this.$route.query.token,
      },
    }
  },
  methods: {
    autenticar() {
      api.redefinirSenha(this.form)
        .then(payload => {
          if (payload.data) {
            Vue.swal({
              title: 'Senha Alterada',
              text: 'Senha Alterada com sucesso! Efetue o login',
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-outline-primary mr-50',
              },
              buttonsStyling: false,
            })
            this.$router.push({ name: 'login' })
          }
        }).catch(error => {
          Vue.swal({
            title: 'Senha não alterada',
            text: error.response.data.message,
            icon: 'error',
            showCancelButton: false,
            confirmButtonText: 'Ok',
            customClass: {
              confirmButton: 'btn btn-outline-primary mr-50',
            },
            buttonsStyling: false,
          })
        })
      return true
    },
  },
}
</script>
